<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Menu Configs" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Contact Text *"
                  v-model="contactText"
                  @input="delete errors.contactText"
                  :error="!!errors.contactText"
                  :error-messages="errors.contactText"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      contactText: '',

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    submit () {
      const data = {
        contactText: this.contactText,
      }
      axios.post(`${process.env.VUE_APP_1LK_API_URL}/admin/menu-config?lang=${this.locale}`, data)
        .then(response => {
          this.showToast('Success')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_1LK_API_URL}/admin/menu-config?lang=${this.locale}`)
        .then(response => {
          console.log(response.data)
          this.contactText = response.data.contactText
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
